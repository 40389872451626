h1 {
    font-family: 'Leckerli One', cursive;
    text-align: center;
    /* text-align: center; */
    font-size: 4rem;
}

h2 {
    font-family: 'Poppins', cursive;
    font-weight: 200;
    text-align: center;
    font-size: 2rem;
}

#mobile {
    text-decoration: line-through;
}

#leave {
    font-family: 'Montserrat', serif;
    font-weight: 600;
}

#crossed {
    text-decoration: line-through;
}

#give_up {
    padding: 1rem;
    margin: 1rem;
}