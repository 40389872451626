#container {
    display: grid;
    grid-template-columns: [license]1fr [love]1fr [info]1fr;
    /* justify-items: center; */
    /* align-content: center; */
    text-align: center;
    width: 100%;
    height: 100%;
    /* background-color: wheat; */
    margin: 0%;
    grid-row-start: foot;
}
#love {
    grid-column-start: love;
}
#license {
    font-family: 'Montserrat';
    grid-column-start: license;
    text-align: left;
    padding-left: 1rem;
    
}
p{
    
    font-family: 'Montserrat', cursive;
    font-size: 1rem;
    color: darkslategrey;
}
a {
    text-decoration: none;
    text-shadow: 3px black;
    color: darkolivegreen ;
}

