.fiddler {
    /* position: absolute; */
    transform: translate(-50%, -50%);
    top: 0%;
    left: 0%;
    margin: 1rem;
    padding: 2rem;
    word-break: keep-all;
    text-overflow: clip;
    white-space: nowrap;
}

#enabled {
    font-size: 2rem;
    font-family: 'Montserrat', sans-serif;
}

#disabled {
    background-color: white;
    font-size: 5rem;
}